// todo.tsx
import React from 'react'

export const Profile: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <h1>comming soon</h1>
    </div>
  )
}

export default Profile
